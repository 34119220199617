const SET_FILTER_KEYWORD = "SET_FILTER_KEYWORD";
const REMOVE_FILTER_KEYWORD = "REMOVE_FILTER_KEYWORD";

const LOAD_ORGANIZATIONS_STARTED = "LOAD_ORGANIZATIONS_STARTED";
const LOAD_ORGANIZATIONS_SUCCESSFUL = "LOAD_ORGANIZATIONS_SUCCESSFUL";
const LOAD_ORGANIZATIONS_FAILED = "LOAD_ORGANIZATIONS_FAILED";

export {
  SET_FILTER_KEYWORD,
  REMOVE_FILTER_KEYWORD,
  LOAD_ORGANIZATIONS_FAILED,
  LOAD_ORGANIZATIONS_STARTED,
  LOAD_ORGANIZATIONS_SUCCESSFUL,
};
