import { call, put, takeLatest } from "redux-saga/effects";
import { LOAD_REPORT_STARTED } from "../constants/reportConstants";
import {
  loadReportFailed,
  loadReportSuccessful,
} from "../actions/reportActions";

import { fetchReportData } from "../api/reportAPI";

function* fetchReport({ payload: { reportId } }) {
  try {
    const reportData = yield call(fetchReportData, reportId);
    yield put(loadReportSuccessful(reportId, reportData));
  } catch (e) {
    yield put(loadReportFailed(reportId, e.message));
  }
}

function* reportSaga() {
  yield takeLatest(LOAD_REPORT_STARTED, fetchReport);
}

export default reportSaga;
