import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { getCurrentReportName } from "selectors/reportSelectors";
import { reportYear } from "appConfig";

const MetaTags = () => {
  const title =
    useSelector(getCurrentReportName) || "TLCC Report - Cambridge Education";

  return (
    <Helmet>
      <meta
        name="description"
        content={`Teaching and Learning Conditions Colorado ${reportYear} Survey Report - prepared by Cambridge Education LLC.`}
      />
      <meta
        property="og:description"
        content={`Web Reports for Teaching and Learning Conditions Colorado Survey ${reportYear}. Created by Cambridge Education LLC. All rights reserved.`}
      />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
        content={`Teaching and Learning Conditions Colorado ${reportYear} Survey Report - prepared by Cambridge Education LLC.`}
      />
      <title>{title}</title>
    </Helmet>
  );
};

export default MetaTags;
