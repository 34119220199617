import { rootOrganizationId } from "../appConfig";

import {
  SET_FILTER_KEYWORD,
  REMOVE_FILTER_KEYWORD,
  LOAD_ORGANIZATIONS_SUCCESSFUL,
  LOAD_ORGANIZATIONS_STARTED,
  LOAD_ORGANIZATIONS_FAILED,
} from "constants/organizationConstants";

const initialState = () => ({
  organizations: {},
  filterKeyword: null,
  rootOrganizationId,
  loaded: false,
  loading: false,
  error: null,
});

export default function organizationReducer(
  state = initialState(),
  action = {}
) {
  switch (action.type) {
    case SET_FILTER_KEYWORD:
      return {
        ...state,
        filterKeyword: action.payload.keyword,
      };
    case REMOVE_FILTER_KEYWORD:
      return {
        ...state,
        filterKeyword: null,
      };
    case LOAD_ORGANIZATIONS_STARTED:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case LOAD_ORGANIZATIONS_FAILED:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    case LOAD_ORGANIZATIONS_SUCCESSFUL:
      return {
        ...state,
        organizations: action.organizations,
        error: null,
        loaded: true,
        loading: false,
      };
    default:
      return state;
  }
}
