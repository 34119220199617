import { call, put, takeLatest } from "redux-saga/effects";
import { LOAD_PDF_REPORT_DATA_STARTED } from "../constants/reportConstants";
import {
  loadPDFReportDataSuccessful,
  loadPDFReportDataFailed,
} from "../actions/reportActions";

import { fetchPDFReportData } from "../api/reportAPI";

function* fetchPDFReport({ payload: { reportId } }) {
  try {
    const pdfReportData = yield call(fetchPDFReportData, reportId);
    yield put(loadPDFReportDataSuccessful(reportId, pdfReportData));
  } catch (e) {
    yield put(loadPDFReportDataFailed(reportId, e.message));
  }
}

function* reportPDFSaga() {
  yield takeLatest(LOAD_PDF_REPORT_DATA_STARTED, fetchPDFReport);
}

export default reportPDFSaga;
