import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

import store from "./store/configStore";

import { unregister } from "./registerServiceWorker";

import "./styles/index.scss";
import AppContainer from "containers/AppContainer/AppContainer";
import { getConfigs, initConfigs } from "appConfig";

const container = document.getElementById("root");
const root = createRoot(container);

initConfigs()
  .then((configs) => {
    if (configs?.debugMode) {
      console.log("App Initialized", configs);
    }
  })
  .finally(() => {
    const configs = getConfigs();
    root.render(
      <Provider store={store}>
        <BrowserRouter basename={configs.baseRoute ?? "/"}>
          <ParallaxProvider>
            <AppContainer />
          </ParallaxProvider>
        </BrowserRouter>
      </Provider>
    );
  });

// registerServiceWorker();
unregister();
