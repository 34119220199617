import { call, put, takeLatest } from "redux-saga/effects";
import { LOAD_ORGANIZATIONS_STARTED } from "../constants/organizationConstants";
import {
  loadOrganizationsFailed,
  loadOrganizationsSuccessful,
} from "../actions/organizationActions";

import { fetchAllOrganizations } from "../api/organizationApi";

function* fetchOrganizations() {
  try {
    const organizations = yield call(fetchAllOrganizations);
    yield put(loadOrganizationsSuccessful(organizations));
  } catch (e) {
    yield put(loadOrganizationsFailed(e.message));
  }
}

function* organizationSaga() {
  yield takeLatest(LOAD_ORGANIZATIONS_STARTED, fetchOrganizations);
}

export default organizationSaga;
