import { rootOrganizationId } from "appConfig";
import {
  LOAD_REPORT_STARTED,
  LOAD_REPORT_SUCCESSFUL,
  LOAD_REPORT_FAILED,
  SET_CURRENT_REPORT_ID,
  SET_CURRENT_REPORT_SCOPE_INDEX,
  LOAD_PDF_REPORT_DATA_STARTED,
  LOAD_PDF_REPORT_DATA_SUCCESSFUL,
  LOAD_PDF_REPORT_DATA_FAILED,
  LOAD_REPORT_DISAGGREGATION_METADATA_STARTED,
  LOAD_REPORT_DISAGGREGATION_METADATA_SUCCESSFUL,
  LOAD_REPORT_DISAGGREGATION_METADATA_FAILED,
  LOAD_HISTORIC_ITEM_WORDINGS_METADATA_STARTED,
  LOAD_HISTORIC_ITEM_WORDINGS_METADATA_SUCCESSFUL,
  LOAD_HISTORIC_ITEM_WORDINGS_METADATA_FAILED,
  LOAD_ITEM_WORDINGS_METADATA_FAILED,
  LOAD_ITEM_WORDINGS_METADATA_STARTED,
  LOAD_ITEM_WORDINGS_METADATA_SUCCESSFUL,
} from "constants/reportConstants.js";

const loadReport = (reportId) => ({
  type: LOAD_REPORT_STARTED,
  payload: {
    reportId,
  },
});

export const loadRootOrgReport = () => loadReport(rootOrganizationId);

const loadReportSuccessful = (reportId, data) => ({
  type: LOAD_REPORT_SUCCESSFUL,
  payload: {
    reportId,
    data,
  },
});

const loadReportFailed = (reportId, failedReason) => ({
  type: LOAD_REPORT_FAILED,
  payload: {
    reportId,
    failedReason,
  },
});

const loadPDFReportData = (reportId) => ({
  type: LOAD_PDF_REPORT_DATA_STARTED,
  payload: {
    reportId,
  },
});

const loadPDFReportDataSuccessful = (reportId, data) => ({
  type: LOAD_PDF_REPORT_DATA_SUCCESSFUL,
  payload: {
    reportId,
    data,
  },
});

const loadPDFReportDataFailed = (reportId, failedReason) => ({
  type: LOAD_PDF_REPORT_DATA_FAILED,
  payload: {
    reportId,
    failedReason,
  },
});

const setCurrentReportId = (reportId) => ({
  type: SET_CURRENT_REPORT_ID,
  payload: {
    reportId,
  },
});

const setCurrentReportScopeIndex = (reportId, index) => ({
  type: SET_CURRENT_REPORT_SCOPE_INDEX,
  payload: {
    reportId,
    index,
  },
});

const loadReportDisaggregationDetailsRequest = () => ({
  type: LOAD_REPORT_DISAGGREGATION_METADATA_STARTED,
});

const loadReportDisaggregationDetailsSuccess = (payload) => ({
  type: LOAD_REPORT_DISAGGREGATION_METADATA_SUCCESSFUL,
  payload,
});

const loadReportDisaggregationDetailsFailed = (error) => ({
  type: LOAD_REPORT_DISAGGREGATION_METADATA_FAILED,
  error,
});

const loadHistoricItemWordingRequest = () => ({
  type: LOAD_HISTORIC_ITEM_WORDINGS_METADATA_STARTED,
});

const loadHistoricItemWordingSuccess = (payload) => ({
  type: LOAD_HISTORIC_ITEM_WORDINGS_METADATA_SUCCESSFUL,
  payload,
});

const loadHistoricItemWordingFailed = (error) => ({
  type: LOAD_HISTORIC_ITEM_WORDINGS_METADATA_FAILED,
  error,
});

export const loadItemWordingRequest = () => ({
  type: LOAD_ITEM_WORDINGS_METADATA_STARTED,
});

export const loadItemWordingSuccess = (payload) => ({
  type: LOAD_ITEM_WORDINGS_METADATA_SUCCESSFUL,
  payload,
});

export const loadItemWordingFailed = (error) => ({
  type: LOAD_ITEM_WORDINGS_METADATA_FAILED,
  error,
});

export {
  loadReport,
  setCurrentReportId,
  loadReportFailed,
  loadReportSuccessful,
  setCurrentReportScopeIndex,
  loadPDFReportData,
  loadPDFReportDataFailed,
  loadPDFReportDataSuccessful,
  loadReportDisaggregationDetailsFailed,
  loadReportDisaggregationDetailsSuccess,
  loadReportDisaggregationDetailsRequest,
  loadHistoricItemWordingRequest,
  loadHistoricItemWordingSuccess,
  loadHistoricItemWordingFailed,
};
