import {
  SET_FILTER_KEYWORD,
  REMOVE_FILTER_KEYWORD,
  LOAD_ORGANIZATIONS_STARTED,
  LOAD_ORGANIZATIONS_SUCCESSFUL,
  LOAD_ORGANIZATIONS_FAILED,
} from "constants/organizationConstants";

const setFilterKeyword = (keyword) => ({
  type: SET_FILTER_KEYWORD,
  payload: { keyword },
});

const removeFilterKeyword = () => ({
  type: REMOVE_FILTER_KEYWORD,
});

const loadOrganizations = () => ({
  type: LOAD_ORGANIZATIONS_STARTED,
});

const loadOrganizationsSuccessful = (organizations) => ({
  type: LOAD_ORGANIZATIONS_SUCCESSFUL,
  organizations,
});

const loadOrganizationsFailed = (error) => ({
  type: LOAD_ORGANIZATIONS_FAILED,
  error,
});

export {
  setFilterKeyword,
  removeFilterKeyword,
  loadOrganizations,
  loadOrganizationsSuccessful,
  loadOrganizationsFailed,
};
