import { PAST_YEAR_DOMAINS } from "appConfig";
import { createSelector } from "reselect";
import { reportPath } from "utils/path-helpers";

import {
  getAllOrganizations,
  getRootOrganization,
} from "./organizationSelectors";

const getCurrentReportId = (state) => state.reports.currentReportId;
const allReports = (state) => state.reports.reports;
const allPdfReports = (state) => state.reports.pdfReports;
const disaggregationMetaDataState = (state) =>
  state.reports.metadata?.disaggregationDetails;
const historicItemWordingsState = (state) =>
  state.reports.metadata?.historicItemWordings;
const itemWordingsState = (state) => state.reports.metadata?.itemWordings;

const getDisaggregationMetadata = createSelector(
  disaggregationMetaDataState,
  (metadata) => metadata?.detailsData
);

export const getItemWordingsMappings = createSelector(
  itemWordingsState,
  (metadata) => metadata?.data ?? {}
);

const getHistoricItemWordings = createSelector(
  historicItemWordingsState,
  (metadata) => metadata?.itemWordings
);

const getDisaggregationMetadataRequest = createSelector(
  disaggregationMetaDataState,
  (metadata) => metadata?.request || {}
);

const getCurrentReport = createSelector(
  [getCurrentReportId, allReports],
  (reportId, allReports = {}) => allReports[reportId]
);

const getRootOrgReport = createSelector(
  [getRootOrganization, allReports],
  (rootOrg, allReports = {}) => allReports[rootOrg?.report_id]
);

export const isRootReport = createSelector(
  [getRootOrganization, getCurrentReportId],
  (rootOrg, currentReportId) => {
    return (
      !!rootOrg?.report_id &&
      rootOrg?.report_id?.toString() === currentReportId.toString()
    );
  }
);

const getCurrentReportData = createSelector(
  [getCurrentReport],
  (currentReport = {}) => currentReport.data
);

const getRootOrgReportData = createSelector(
  [getRootOrgReport],
  (rootOrgReport) => rootOrgReport?.data
);

const getCurrentReportScopes = createSelector(
  [getCurrentReportData],
  (reportData = []) =>
    reportData.map(({ rdo: { report_scope = {} } }) => {
      const sectionScopes = report_scope.section_scopes || [];
      const sectionScopeValues = sectionScopes.map(({ values = [] }) =>
        values.join(", ")
      );
      const schoolNames = (report_scope.school_names || []).join(", ");
      return `${schoolNames}${
        schoolNames.length > 0 ? " - " : ""
      }${sectionScopeValues}`;
    })
);

const getCurrentReportScopeIndex = createSelector(
  [getCurrentReport],
  (currentReport = {}) => currentReport.currentReportScopeIndex
);

const getCurrentReportScope = createSelector(
  [getCurrentReportData, getCurrentReportScopeIndex],
  (reportData = [], scopeIndex) => reportData[scopeIndex]
);

const getCurrentRootOrgReportScope = createSelector(
  [getRootOrgReportData, getCurrentReportScopeIndex],
  (reportData = [], scopeIndex) => reportData[scopeIndex]
);

const getCurrentRDO = createSelector(
  [getCurrentReportScope],
  (reportScope) => reportScope?.rdo
);

const getCurrentRootOrgRDO = createSelector(
  [getCurrentRootOrgReportScope],
  (reportScope) => reportScope?.rdo
);

const getAdministration = createSelector(
  [getCurrentReportScope],
  (reportScope) => reportScope?.administration
);

const getCurrentReportName = createSelector(
  [getCurrentReportScope],
  (reportScope) =>
    reportScope
      ? `${reportScope.rdo.subject_name} ${reportScope.administration}`
      : null
);

// IMPORTANT: report_id should be the same as organization id!
const getCurrentReportOrganizationDetails = createSelector(
  [getCurrentReportId, getAllOrganizations],
  (currentReportId, allOrganizations) => allOrganizations[currentReportId]
);

// IMPORTANT: district names must be unique;
export const getCurrentReportParentDetails = createSelector(
  [getCurrentReportOrganizationDetails, getAllOrganizations],
  (currentOrg, allOrgs = {}) => {
    const parentOrg = allOrgs[currentOrg?.parent_id];

    return {
      parentReportId: parentOrg?.report_id,
      parentType: parentOrg?.type,
      parentName: parentOrg?.name,
    };
  }
);

// returns Name => ReportId mapping
export const getCurrentReportChildrenReportIdMapping = createSelector(
  [getCurrentReportOrganizationDetails, getAllOrganizations],
  (currentOrg, allOrgs = {}) => {
    const children = (currentOrg?.children_ids ?? [])
      .map((childId) => allOrgs[childId])
      .filter((child) => !!child?.report_id);

    return children.reduce((mapping, child) => {
      mapping[child.name] = child.report_id;

      return mapping;
    }, {});
  }
);

const getCurrentReportTotalRespondents = createSelector(
  [getCurrentReportOrganizationDetails],
  ({ total }) => total
);

// Assuming there is only one `survey` in the RDO!
const getCurrentReportScopeSurveyName = createSelector(
  [getCurrentRDO],
  (rdo) => rdo?.surveys[0].survey_name
);

const getCurrentReportScopeSurveys = createSelector(
  [getCurrentRDO],
  (rdo) => rdo?.surveys
);

const getCurrentRootOrgReportScopeSurveys = createSelector(
  [getCurrentRootOrgRDO],
  (rdo) => rdo?.surveys
);

const getCurrentReportScopeDemographicItems = createSelector(
  [getCurrentReportScopeSurveys],
  ([{ demographics }]) => demographics
);

const getScoreRange = createSelector(
  [getCurrentReportScopeSurveys],
  ([
    {
      measure_type: { min_score, max_score },
    },
  ]) => ({ minScore: min_score, maxScore: max_score })
);

const getCurrentScopeModules = createSelector(
  [getCurrentReportScopeSurveys],
  ([{ modules }]) => modules
);

const getCurrentScopeRootOrgModules = createSelector(
  [getCurrentRootOrgReportScopeSurveys],
  ([{ modules }]) => modules
);

const getConstructFromModules = (
  modules = [],
  { moduleIndex, constructProviderKey }
) => {
  const mod = modules[moduleIndex];
  if (!mod) {
    return null;
  }
  const {
    constructs,
    report_sections: { at_a_glance = {}, construct_details },
  } = mod;
  const { construct_summary = [] } = at_a_glance;
  const index = constructs.findIndex(
    ({ construct_provider_key }) =>
      construct_provider_key === constructProviderKey
  );

  if (index >= 0) {
    const construct = constructs[index];
    const summary =
      construct_summary.find(
        ({ construct_provider_key }) =>
          construct_provider_key === constructProviderKey
      ) || {};
    const details =
      construct_details.find(
        ({ construct_name }) => construct_name === construct.display_name
      ) || {};
    return {
      constructIndex: index,
      ...construct,
      ...summary,
      ...details,
    };
  }

  return null;
};

const getConstruct = createSelector(
  [
    getCurrentScopeModules,
    (_, { moduleIndex, constructProviderKey }) => ({
      moduleIndex,
      constructProviderKey,
    }),
  ],
  getConstructFromModules
);

const getRootOrgConstruct = createSelector(
  [
    getCurrentScopeRootOrgModules,
    (_, { moduleIndex, constructProviderKey }) => ({
      moduleIndex,
      constructProviderKey,
    }),
  ],
  getConstructFromModules
);

const getModuleByIndex = createSelector(
  [getCurrentScopeModules, (_, { moduleIndex }) => moduleIndex],
  (modules, moduleIndex) => modules[moduleIndex]
);

const getRootOrgModuleByIndex = createSelector(
  [getCurrentScopeRootOrgModules, (_, { moduleIndex }) => moduleIndex],
  (modules, moduleIndex) => modules[moduleIndex]
);

const makeGetConstruct = () =>
  createSelector(getConstruct, (construct) => construct);

export const makeGetRootOrgConstruct = () =>
  createSelector(getRootOrgConstruct, (construct) => construct);

const makeGetModuleByIndex = () =>
  createSelector(getModuleByIndex, (mod) => mod);

export const makeGetRootOrgModuleByIndex = () =>
  createSelector(getRootOrgModuleByIndex, (mod) => mod);

const getReportOptions = createSelector(
  [getCurrentReportScope],
  (scope) => scope?.options
);

const getMeasureType = createSelector(
  [getReportOptions],
  ({ measure_type_name }) => measure_type_name
);

const getInsightsChildrenTypeName = createSelector(
  [getReportOptions],
  ({ children_type }) => {
    switch (children_type) {
      case "K12School":
        return "School";
      case "K12Lea":
        return "District";
      case "K12Section":
        return "Classroom";
      default:
        return children_type;
    }
  }
);

const getScoreSuffix = createSelector([getMeasureType], (typeName) => {
  switch (typeName) {
    case "percentage_of_favorable_responses":
      return "%";
    default:
      return "";
  }
});

const getScoreDecimalPlaces = createSelector([getMeasureType], (typeName) => {
  switch (typeName) {
    case "average_of_response_values":
      return 1;
    default:
      return 0;
  }
});

const getBenchmarkLabels = createSelector(
  [getReportOptions],
  ({ comparative_labels = [], comparative_types = [] }) =>
    comparative_types.map((type) => {
      const label = comparative_labels.find(
        ({ type: labelType }) => labelType === type
      );
      if (label) {
        return label.friendly_name;
      } else {
        return null;
      }
    })
);

const getCurrentReportScopeReportName = createSelector(
  [getCurrentReportScope],
  (scope) => scope?.report
);

const getCurrentReportScopeSubjectName = createSelector(
  [getCurrentRDO],
  (rdo) => rdo?.subject_name
);

const getCurrentReportScopeSectionScopes = createSelector(
  [getCurrentRDO],
  (rdo) =>
    (rdo?.report_scope?.section_scopes ?? []).map(({ type, values }) => ({
      type,
      value: values.join(", "),
    }))
);

const getCurrentReportScopeSchoolNames = createSelector(
  [getCurrentRDO],
  (rdo) => rdo?.report_scope?.school_names
);

const getCurrentReportScopeDistrictNames = createSelector(
  [getCurrentRDO],
  (rdo) => rdo?.leas_name
);

const getCurrentReportScopeResponsesCount = createSelector(
  [getCurrentRDO],
  (rdo) => rdo?.report_scope.number_of_respondents
);

const getCurrentReportPDFData = createSelector(
  [allPdfReports, getCurrentReportId],
  (pdfReports, currentReportId) => pdfReports[currentReportId]
);

const getCurrentReportScopeHistoricalAdministration = createSelector(
  [getCurrentRDO],
  (rdo) => rdo?.historical_administrations
);

export const getRootOrgReportScopeHistoricalAdministration = createSelector(
  [getCurrentRootOrgRDO],
  (rdo) => rdo?.historical_administrations
);

const getCurrentSubjectType = createSelector(
  [getCurrentRDO],
  (rdo) => rdo?.subject_type
);

const findConstructProviderKeyByName = (constructs, name) =>
  (constructs.filter(({ display_name }) => display_name === name)[0] || {})
    .construct_provider_key;

const getCurrentReportSections = createSelector(
  [
    getCurrentReportScopeSurveys,
    getCurrentReportId,
    getCurrentReportScopeHistoricalAdministration,
  ],
  (surveys = [], currentReportId, historicalAdministrations = []) => {
    const basePath = reportPath({ reportId: currentReportId });

    // {'TLCC 2018' => 'https://...' }
    const pastYearReports = {};

    const reportSections = surveys.reduce(
      (sections, { demographics, modules }, index) => {
        const surveyPath = `${basePath}/surveys/${index}`;
        if (demographics && demographics.length > 0) {
          const demographicsPath = `${surveyPath}/demographics`;
          sections.push({
            id: `demographics-${index}`,
            name: "Demographics",
            path: demographicsPath,
          });
        }

        modules.forEach(
          (
            {
              display_name,
              constructs,
              report_sections: {
                insights,
                construct_details,
                module_items,
                at_a_glance = {},
              },
            },
            moduleIndex
          ) => {
            const modulePath = `${surveyPath}/modules/${moduleIndex}`;

            const moduleSection = {
              id: `module-${moduleIndex}`,
              name: "Overview",
              path: `${modulePath}/overview`,
              header: display_name,
              subSections: [],
            };

            construct_details.forEach(({ construct_name }) => {
              const providerKey = findConstructProviderKeyByName(
                constructs,
                construct_name
              );
              if (providerKey) {
                const constructPath = `${modulePath}/constructs/${providerKey}`;
                moduleSection.subSections.push({
                  id: `m${moduleIndex}-${providerKey}`,
                  name: construct_name,
                  path: constructPath,
                });
              }
            });

            if (module_items && module_items.length > 0) {
              const moduleItemsPath = `${modulePath}/other-items`;
              moduleSection.subSections.push({
                id: `m${moduleIndex}-other-items`,
                name:
                  construct_details && construct_details.length > 0
                    ? "Other Items"
                    : "Module Items",
                path: moduleItemsPath,
              });
            }

            sections.push(moduleSection);

            if (insights) {
              const insightsPath = `${modulePath}/insights`;
              sections.push({
                id: `m${moduleIndex}-insights`,
                name: "Insights",
                path: insightsPath,
              });
            }

            const { historical_scores = [] } = at_a_glance;
            historicalAdministrations.forEach((histAdmin, index) => {
              const domain = PAST_YEAR_DOMAINS[histAdmin];
              if (domain && historical_scores[index] != null) {
                pastYearReports[histAdmin] = domain;
              }
            });
          }
        );

        return sections;
      },
      []
    );

    reportSections.push({
      id: `how-to-read`,
      name: "How To Read",
      header: "Resources",
      path: `${basePath}/how-to-read`,
    });

    reportSections.push({
      id: `download-pdf`,
      name: "Download PDF Reports",
      path: `${basePath}/download-pdf`,
    });

    Object.keys(pastYearReports).forEach((year, index) => {
      const domain = pastYearReports[year];
      reportSections.push({
        id: `past-report-${year}`,
        name: year,
        header: index === 0 ? "Past Reports" : undefined,
        href: [domain, basePath].join(""),
      });
    });

    return reportSections;
  }
);

const flattenReportSections = (reportSections) => {
  return reportSections.reduce((allSections, section) => {
    const { subSections, ...currentSection } = section;
    return [
      ...allSections,
      currentSection,
      ...flattenReportSections(subSections ?? []),
    ];
  }, []);
};

const getFlattenCurrentReportSections = createSelector(
  [getCurrentReportSections],
  (reportSections) => flattenReportSections(reportSections)
);

const getDefaultReportDefaultPath = createSelector(
  [getFlattenCurrentReportSections],
  (sections) => sections?.[0]?.path
);

const makeGetNeighborSectionsByPath = createSelector(
  [getFlattenCurrentReportSections],
  (reportSections) => (currentPath) => {
    const currentIndex = reportSections.findIndex(
      ({ path }) => !!currentPath.match(new RegExp(`${path}$`))
    );
    return [reportSections[currentIndex - 1], reportSections[currentIndex + 1]];
  }
);

export {
  getCurrentReportId,
  getCurrentReport,
  getCurrentReportScopes,
  getCurrentReportScopeIndex,
  getCurrentReportScope,
  getAdministration,
  getCurrentReportTotalRespondents,
  getCurrentReportScopeSurveyName,
  getCurrentReportScopeReportName,
  getCurrentReportScopeSectionScopes,
  getCurrentReportScopeSchoolNames,
  getCurrentReportScopeDistrictNames,
  getCurrentReportScopeResponsesCount,
  getCurrentReportScopeSubjectName,
  getCurrentReportScopeSurveys,
  getScoreRange,
  getScoreSuffix,
  getScoreDecimalPlaces,
  getBenchmarkLabels,
  getCurrentReportScopeDemographicItems,
  getCurrentScopeModules,
  makeGetConstruct,
  makeGetModuleByIndex,
  getInsightsChildrenTypeName,
  getCurrentReportPDFData,
  getMeasureType,
  getCurrentReportScopeHistoricalAdministration,
  getCurrentSubjectType,
  findConstructProviderKeyByName,
  getCurrentReportSections,
  getDisaggregationMetadata,
  getDisaggregationMetadataRequest,
  makeGetNeighborSectionsByPath,
  getDefaultReportDefaultPath,
  getCurrentReportName,
  getHistoricItemWordings,
};
