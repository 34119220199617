import { combineReducers } from "redux";
import reportReducer from "./reportReducer";
import organizationReducer from "./organizationReducer";

const rootReducer = combineReducers({
  reports: reportReducer,
  organizations: organizationReducer,
});

export default rootReducer;
