const LOAD_REPORT_STARTED = "LOAD_REPORT_STARTED";
const LOAD_REPORT_SUCCESSFUL = "LOAD_REPORT_SUCCESSFUL";
const LOAD_REPORT_FAILED = "LOAD_REPORT_FAILED";
const SET_CURRENT_REPORT_ID = "SET_CURRENT_REPORT_ID";
const SET_CURRENT_REPORT_SCOPE_INDEX = "SET_CURRENT_REPORT_SCOPE_INDEX";
const LOAD_PDF_REPORT_DATA_STARTED = "LOAD_PDF_REPORT_DATA_STARTED";
const LOAD_PDF_REPORT_DATA_SUCCESSFUL = "LOAD_PDF_REPORT_DATA_SUCCESSFUL";
const LOAD_PDF_REPORT_DATA_FAILED = "LOAD_PDF_REPORT_DATA_FAILED";
const LOAD_REPORT_DISAGGREGATION_METADATA_STARTED =
  "LOAD_REPORT_DISAGGREGATION_METADATA_STARTED";
const LOAD_REPORT_DISAGGREGATION_METADATA_SUCCESSFUL =
  "LOAD_REPORT_DISAGGREGATION_METADATA_SUCCESSFUL";
const LOAD_REPORT_DISAGGREGATION_METADATA_FAILED =
  "LOAD_REPORT_DISAGGREGATION_METADATA_FAILED";
const LOAD_HISTORIC_ITEM_WORDINGS_METADATA_STARTED =
  "LOAD_HISTORIC_ITEM_WORDINGS_METADATA_STARTED";
const LOAD_HISTORIC_ITEM_WORDINGS_METADATA_SUCCESSFUL =
  "LOAD_HISTORIC_ITEM_WORDINGS_METADATA_SUCCESSFUL";
const LOAD_HISTORIC_ITEM_WORDINGS_METADATA_FAILED =
  "LOAD_HISTORIC_ITEM_WORDINGS_METADATA_FAILED";

const LOAD_ITEM_WORDINGS_METADATA_STARTED =
  "LOAD_ITEM_WORDINGS_METADATA_STARTED";
const LOAD_ITEM_WORDINGS_METADATA_SUCCESSFUL =
  "LOAD_ITEM_WORDINGS_METADATA_SUCCESSFUL";
const LOAD_ITEM_WORDINGS_METADATA_FAILED = "LOAD_ITEM_WORDINGS_METADATA_FAILED";

export {
  LOAD_REPORT_STARTED,
  LOAD_REPORT_SUCCESSFUL,
  LOAD_REPORT_FAILED,
  SET_CURRENT_REPORT_ID,
  SET_CURRENT_REPORT_SCOPE_INDEX,
  LOAD_PDF_REPORT_DATA_STARTED,
  LOAD_PDF_REPORT_DATA_SUCCESSFUL,
  LOAD_PDF_REPORT_DATA_FAILED,
  LOAD_REPORT_DISAGGREGATION_METADATA_STARTED,
  LOAD_REPORT_DISAGGREGATION_METADATA_SUCCESSFUL,
  LOAD_REPORT_DISAGGREGATION_METADATA_FAILED,
  LOAD_HISTORIC_ITEM_WORDINGS_METADATA_FAILED,
  LOAD_HISTORIC_ITEM_WORDINGS_METADATA_SUCCESSFUL,
  LOAD_HISTORIC_ITEM_WORDINGS_METADATA_STARTED,
  LOAD_ITEM_WORDINGS_METADATA_FAILED,
  LOAD_ITEM_WORDINGS_METADATA_STARTED,
  LOAD_ITEM_WORDINGS_METADATA_SUCCESSFUL,
};
