import React, { useCallback, useState, useEffect } from "react";
import { Button } from "reactstrap";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./backToTopButton.styles.scss";

const THRESHOLD = 500;

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const BackToTopButton = () => {
  const [show, setShow] = useState(window.scrollY >= THRESHOLD);

  const handleWindowScroll = useCallback(() => {
    setShow(window.scrollY >= THRESHOLD);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);

    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, [handleWindowScroll]);

  if (!show) {
    return null;
  }

  return (
    <Button className="back-to-top-button" onClick={scrollToTop}>
      Back to Top <FontAwesomeIcon icon={faArrowAltCircleUp} />
    </Button>
  );
};

export default BackToTopButton;
