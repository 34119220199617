export const minResponseThreshold = 5;
export const minResponseRateThreshold = 0.5;
export const minDistrictResponseThreshold = 10;
export const minDistrictResponseRateThreshold = 0.4;

export const DISTRICT_TYPE = "district";

export const ROOT_ORG_ALIAS = "Colorado";

export const reportYear = 2022;
export const apiBaseUrl = "/api";
export const rootOrganizationId = 401722;

export const PAST_YEAR_DOMAINS = {
  "TLCC 2018": "",
  "TLCC 2020": "",
};

export const ROLE_DISPLAY_NAMES = {
  teachers: "Teachers",
  esp: "Education Support Professionals",
  leaders: "Leaders",
};

let configs = {
  debugMode: false,
  dataRequestFormUrl: null,
  supportFormUrl: null,
  feedbackFormUrl: null,
  summaryReportUrl: null,
  tlccOfficialSite: null,
  tlcc2018ReportUrl: null,
  tlcc2020ReportUrl: null,
  supportEmail: null,
  baseRoute: "/",
};

export const initConfigs = () =>
  fetch(`/configs.json`)
    .then((res) => res.json())
    .then((remoteConfigs) => {
      Object.assign(configs, remoteConfigs || {});
      PAST_YEAR_DOMAINS["TLCC 2018"] = configs.tlcc2018ReportUrl;
      PAST_YEAR_DOMAINS["TLCC 2020"] = configs.tlcc2020ReportUrl;
      return configs;
    })
    .catch((e) => console.error(`Unable to fetch app configurations:`, e));

export const getConfigs = () => ({
  ...configs,
});
