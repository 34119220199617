import { fork, all } from "redux-saga/effects";
import reportSaga from "./reportSagas";
import reportPDFSaga from "./reportPDFSagas";
import organizationSagas from "./organizationSagas";
import reportMetaDataSagas from "./reportMetaDataSagas";

export default function* rootSaga() {
  yield all([
    fork(reportSaga),
    fork(reportPDFSaga),
    fork(organizationSagas),
    fork(reportMetaDataSagas),
  ]);
}
