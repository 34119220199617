import { apiBaseUrl, reportYear } from "../appConfig";

const fetchAllOrganizations = async () => {
  const response = await fetch(
    `${apiBaseUrl}/data/${reportYear}/organizations.json`
  );
  return response.json();
};

export { fetchAllOrganizations };
