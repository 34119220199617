import React from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";

import "./loadingBox.styles.scss";

const LoadingBox = ({ title = "Loading...", width = 60, height = 60 }) => (
  <div className="loading-box">
    <Oval
      ariaLabel="loading-indicator"
      height={height}
      width={width}
      strokeWidth={2}
      strokeWidthSecondary={2}
      color="#2b86c7"
      secondaryColor="#8cc2e9"
    />
    <p>{title}</p>
  </div>
);

LoadingBox.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LoadingBox;
