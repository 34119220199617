export const reportPath = ({ reportId }) => `/reports/${reportId}`;
export const surveyPath = ({ reportId, surveyIndex }) =>
  [reportPath({ reportId }), `surveys/${surveyIndex}`].join("/");
export const modulePath = ({ reportId, surveyIndex, moduleIndex }) =>
  [surveyPath({ reportId, surveyIndex }), `modules/${moduleIndex}`].join("/");

export const constructPath = ({
  providerKey,
  reportId,
  surveyIndex,
  moduleIndex,
}) =>
  [
    modulePath({ reportId, surveyIndex, moduleIndex }),
    `constructs/${providerKey}`,
  ].join("/");

export const moduleItemsPath = ({ reportId, surveyIndex, moduleIndex }) =>
  [modulePath({ reportId, surveyIndex, moduleIndex }), "other-items"].join("/");
