import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { Alert, Button, Container } from "reactstrap";

import PageHeader from "components/PageHeader/PageHeader";
import PageFooter from "components/PageFooter/PageFooter";

import "./app.styles.scss";
import BackToTopButton from "components/BackToTopButton/BackToTopButton";
import MetaTags from "./MetaTags";
import LoadingBox from "components/LoadingBox/LoadingBox";

const ReportLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "ReportLayout" */ "components/ReportLayout/ReportLayout"
  )
);
const NotFoundPage = React.lazy(() =>
  import(
    /* webpackChunkName: "NotFound" */ "components/NotFoundPage/NotFoundPage"
  )
);
const Home = React.lazy(
  /* webpackChunkName: "Homepage" */ () => import("components/Home/Home")
);

const App = ({ loaded, loading, error, loadOrganizations }) => (
  <div className="app-container">
    <MetaTags />
    <PageHeader />
    <main>
      {error && (
        <Container>
          <Alert color="danger">
            <h4 className="alert-heading">Error</h4>
            <p>Failed to load organizations data: {error}</p>
            <hr />
            <Button onClick={loadOrganizations}>Retry</Button>
          </Alert>
        </Container>
      )}
      {loading && (
        <Container>
          <LoadingBox title="Initializing" />
        </Container>
      )}
      {loaded && (
        <Suspense fallback={<LoadingBox />}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/reports/:reportId" component={ReportLayout} />
            <Route component={NotFoundPage} />
          </Switch>
        </Suspense>
      )}
    </main>
    <PageFooter />
    <BackToTopButton />
  </div>
);

App.propTypes = {
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  loadOrganizations: PropTypes.func.isRequired,
};

export default App;
